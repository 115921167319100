<template>
  <div>
    <b-overlay :show="user.isFetchingBankAccount">
      <b-card>
        <bank-account-form
          v-model="userBankAccountDetail"
          :is-form-create="isFormCreate"
          :is-loading="user.isAddingBankAccount || user.isUpdatingBankAccount"
          @submit="onSubmit"
          @close="navigateRoute"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  page: {
    title: 'Bank account detail',
  },
  components: {
    BankAccountForm: () =>
      import('@/views/common-forms/BankAccountForm.vue'),
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['userBankAccountDetail']),
    isFormCreate() {
      return this.$route.name === 'CREATE_BANK_ACCOUNT'
    },
    bankAccountId() {
      return this.isFormCreate ? null : this.$route.params.id
    },
  },
  created() {
    if (this.bankAccountId) {
      this.fetchBankAccount(this.bankAccountId)
    }
  },
  methods: {
    ...mapActions(['fetchBankAccount', 'addBankAccount', 'updateBankAccount']),
    navigateRoute() {
      this.$router.push('/bank-accounts/list')
    },

    onSubmit(data) {
      if (this.bankAccountId) {
        this.updateBankAccount({ id: this.bankAccountId, data })
      } else {
        this.addBankAccount(data)
      }
    },
  },
}
</script>
